<template>
  <div class="message" v-loading="pageLoading">
    <!-- 面包屑 -->
    <div class="cont">
      <div class="cont-list">
        <div class="flex-row m-b-10">
          <div class="flex-cell"></div>
          <div class="btns cursor-pointer" @click="isPopShow = true">
            我要留言
          </div>
        </div>
        <div class="" v-if="dataList.length>0">
          <div
          class="border-b list-cell cursor-pointer p-20"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div class="flex-row txt-center">
            <img class="face" src="@/assets/icon/admin.png" alt="" />
            <div class="flex-cell font-weight m-l-10">{{item.lm_by}} {{item.lm_time}}</div>
          </div>
          <div class="flex-cell info">{{item.lm_content}}</div>
          <div class="flex-row txt-center admins">
            <img class="face" src="@/assets/icon/youk.png" alt="" />
            <div class="flex-cell font-weight m-l-10">
              管理员 在{{item.lm_reply_time}} 回复：<span class="reply">{{item.lm_reply_content}}</span>
            </div>
          </div>
        </div>
        </div>
      </div>
      <el-empty v-if="dataList.length<=0" description="暂无数据"></el-empty>
      <pagination
        v-if="dataList.length>0"
        :total="total"
        :curPage="pageParams.page"
        :perPage="pageParams.size"
        @current-change="currentChange"
      />
    </div>
    <el-dialog title="我要留言" :visible.sync="isPopShow" width="700px" :close-on-click-modal="false">
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        label-width="80px"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="formData.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="formData.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="formData.content"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="captcha">
          <div class="flex-row txt-center">
            <el-input
              class="flex-cell"
              v-model="formData.captcha"
              autocomplete="off"
            ></el-input>
            <div class="flex-cell flex-row txt-center">
              <div class="codeImg" @click="refreshCode()">
                <s-identify :identifyCode="identifyCode"></s-identify>
              </div>
              <div class="flex-cell"></div>
            </div>
            
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer txt-center">
        <!-- <el-button type="info" @click="resetForm('formData')">关 闭</el-button> -->
        <el-button type="danger" @click="doSubmit('formData')"
          >提 交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { requestPost, requestGet } from "@/api/home/index";
import sIdentify from "@/components/sIdentify.vue";
export default {
  name: "Home",
  components: { sIdentify },
  data() {
    var checkCode = (rule, value, callback) => {
      if (value != this.identifyCode) {
        callback(new Error("验证码输入有误，请重新输入"));
      } else {
        callback();
      }
    };
    return {
      pageLoading: false,
      identifyCode: "",
      identifyCodes: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
      ],
      pageParams: {
        page: 1,
        size: 10,
      },
      total: 0,
      dataList: [],
      isPopShow: false,
      formData: {},
      rules: {
        title: [{ required: true, message: "请输入标题！", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容！", trigger: "blur" }],
        captcha: [
          { required: true, message: "请输入验证码！", trigger: "blur" },
          { validator: checkCode, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    // 加载数据
    l_loadData(this)
    this.refreshCode();
  },
  methods: {
    // 生成随机数
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 更新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
      console.log("当前验证码:", this.identifyCode);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      console.log("data, len:", data, len);
      for (let i = 0; i < len; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length - 1)];
      }
    },
    // @@ 改变当前页事件
    currentChange(val) {
      this.pageParams.page = val;
      l_loadData(this);
    },
    // 提交
    doSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          c_doSubmit(this)
        }
      });
    },
    // 取消重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.isPopShow = false;
    },
  },
};

// 获取数据
function l_loadData(pg){
  pg.pageLoading = true
  requestGet("api/feedback", pg.pageParams)
    .then((res) => {
      pg.dataList = res.data.pageData || []
      pg.total = res.data.count
      pg.pageLoading = false
    })
    .catch((error) => {
      pg.$message.error(error);
      pg.pageLoading = false
    });
}

function c_doSubmit(pg){
  var values = {
    name: pg.formData.name,
    title: pg.formData.title,
    content: pg.formData.content
  }
  requestPost("api/feedback", values)
    .then((res) => {
      if(res.code == '200'){
        pg.$message.success('留言成功，请等待审核');
        pg.resetForm('formData')
        pg.isPopShow = false;
      }else{
        pg.$message.error(res.msg);
      }
    })
    .catch((error) => {
      pg.$message.error(error);
    });
}
</script>
<style lang="scss" scoped>
.message {
  width: 1200px;
  margin: 0 auto;
  .cont {
    background: #fff;
    padding: 20px;
    .cont-list {
      .btns {
        background: #ff4d4f;
        color: #fff;
        font-size: 14px;
        padding: 7px 15px;
        border-radius: 3px;
      }
      .list-cell {
        border-bottom: 1px solid #e8e8e8;
        font-size: 14px;
        .face {
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }
        .info {
          margin: 10px 0;
          margin-left: 50px;
          word-break: break-all;
        }
        .admins {
          margin-left: 50px;
          .reply {
            display: inline-block;
            font-size: 13px;
            font-weight: 400;
            word-break: break-all;
          }
        }
      }
      .list-cell:hover {
        background: #e6f7ff;
      }
    }
  }
  .codeImg {
    height: 40px;
    margin-left: 20px;
  }
}
::v-deep .el-input__inner{
    border: 1px solid #DCDFE6!important;
  }
</style>
